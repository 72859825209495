<script>
import axios from "axios";

export default {
  name: "OrdersStatistics",
  emits: ['onStatisticsLoaded'],
  data() {
    return {
      statistics: {
        "missing_invoice": 0,
        "unpaid_invoice": 0,
        "rate_mismatch": 0,
        "quantity_mismatch": 0,
        "closed": 0,
        "unclosed": 0,
        "closable": 0,
      },
      isLoading: false,
      initialLoadDone: false
    }
  },
  methods: {
    async getStatistics() {
      this.isLoading = true
      try {
        let filter_year = this.$route.query.date || undefined
        let response = await axios.get('/order/list_by_status/statistics/', {
          params: {
            year: filter_year
          }
        })
        this.statistics = response.data || {}
        this.isLoading = false
        this.$emit('onStatisticsLoaded', this.statistics)
      } catch {
        alert("error")
        this.statistics = {}
        this.isLoading = false
        this.$emit('onStatisticsLoaded', this.statistics)
      }
    },
    validateYear(dateString) {
      if (!dateString) return null;

      const year = parseInt(dateString, 10);
      const currentYear = new Date().getFullYear();

      if (isNaN(year) || year < 2022 || year > currentYear || dateString.length !== 4) {
        console.warn(`Invalid year: ${dateString}. Year should be between 2022 and ${currentYear}.`);
        return null;
      }

      return year;
    },
  },
  mounted() {
    this.getStatistics();
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newValue, oldValue) {
        const newYear = this.validateYear(newValue.date);
        const oldYear = oldValue ? this.validateYear(oldValue.date) : undefined;

        if (!this.initialLoadDone) {
          // Initial load
          if (newYear) {
            this.getStatistics();
            this.initialLoadDone = true;
          }
        } else if (newYear !== oldYear) {
          // Year has changed
          this.getStatistics();
        }
      }
    }
  },
}
</script>

<template>
  <div v-if="isLoading">
    <b-spinner></b-spinner>
  </div>
  <div v-else class="row mb-3 g-4">
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-primary rounded-circle fs-4 material-shadow">
                <i class="mdi mdi-code-greater-than-or-equal align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">QUANTITY MISMATCH</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.quantity_mismatch || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                <i class="mdi mdi-clipboard-text-off align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">INVOICES MISSING</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.missing_invoice || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                <i class="ri-money-dollar-circle-fill align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">RATE MISMATCH</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.rate_mismatch || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-primary rounded-circle fs-4 material-shadow">
                <i class="ri-file-paper-fill align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">UNPAID INVOICE</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.unpaid_invoice || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-primary rounded-circle fs-3 material-shadow">
                <i class="bx bxs-x-circle align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">NOT CLOSED ORDERS</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.unclosed || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-6 col-xl-3 col-xxl-2">
      <b-card no-body class="mb-0 h-100">
        <b-card-body>
          <div class="d-flex align-items-center h-100">
            <div class="avatar-sm flex-shrink-0">
              <span class="avatar-title bg-light text-success rounded-circle fs-3 material-shadow">
                <i class="bx bxs-check-circle align-middle"></i>
              </span>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">CLOSED ORDERS</p>
              <h4 class="mb-0"><span class="counter-value">{{ statistics.closed || 0 }}</span></h4>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<style scoped>

</style>