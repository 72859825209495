<script>
import PageHeader from "@/components/page-header.vue";
import OrdersList from "@/views/pages/orders/components/OrdersList.vue";
// import ManagersStatistics from "@/views/pages/orders/components/ManagersStatistics.vue";
import OrdersStatistics from "@/views/pages/orders/components/OrdersStatistics.vue";


export default {
  name: "index",
  components: {
    PageHeader,
    OrdersList,
    // ManagersStatistics,
    OrdersStatistics
  },
  data() {
    return {
      title: 'Orders List',
      items: [
        {
          text: "Applications",
          href: "/",
        },
        {
          text: "Orders List",
          active: true,
        },
      ],
    }
  },
  methods: {
    onStatisticsLoaded(statistics) {
      this.$refs.ordersList.setCloseableOrdersCount(statistics.closable || 0)
    }
  }
}
</script>

<template>
  <PageHeader :items="items" :title="title"/>
  <div class="row align-items-start">
    <div class="col-12">
      <OrdersStatistics ref="ordersStatistics" @on-statistics-loaded="onStatisticsLoaded"/>
    </div>
    <div class="col-xxl-12">
      <OrdersList
          @performed-order-closing="this.$refs.ordersStatistics.getStatistics()"
          ref="ordersList"/>
    </div>
<!--    <ManagersStatistics class="col-12 col-xxl-3"/>-->
  </div>
</template>

<style scoped>

</style>